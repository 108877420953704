<template>
  <v-card :loading='loaderFaqs' class='d-flex flex-column rounded-lg elevation-3 ma-5 pb-5'>
    <v-card-title class='font-weight-bold headline px-7'>{{ sections.faqs || 'FAQs' }}</v-card-title>
    <v-row justify='center'>
      <v-expansion-panels
        class='ma-5 pa-3'
        focusable
      >
        <v-expansion-panel
          v-for='question in faqs'
          :key='question.id'
        >
          <v-expansion-panel-header class='primary white--text'>
            {{ question.question }}
            <template #actions>
              <v-icon color='white'>$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class='pa-5' v-html='question.answer'></v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-card>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'FAQs',
  data: () => ({
    loaderFaqs: false
  }),
  computed: {
    ...mapState({
      faqs: state => state.employeeStore.faqs,
      sections: state => state.cmsStore.sections
    })
  },
  async created() {
    this.loaderFaqs = true
    try {
      await this.getFAQContent()
    } catch (e) {
      this.loaderFaqs = false
    }
    this.loaderFaqs = false
  },
  methods: {
    ...mapActions('employeeStore', ['getFAQContent'])
  }
}
</script>

<style scoped>

</style>